@media screen and (max-width: 992px) {
	.popular {
		.slick-prev {
			left: -15px;
		}
		.slick-next {
			right: 0;
		}
	}
	.offerSlider__title {
		width: 255px;
		text-align: left;
	}
	.btn {
		margin: 0 !important;
	}
	.offerSlider__first {
		background: url(../img/bgMobile2.jpg) no-repeat right bottom !important;
	}
	.offerSlider__second {
		background: url(../img/bgMobile.jpg) no-repeat right bottom !important;
	}
	.offerSlider__third {
		background: url(../img/bgMobile3.jpg) no-repeat right bottom !important;
	}
	.offerSlider__content {
		margin-left: 30px !important;
	}
	.offerSlider {
		.slick-dots {
			width: 100px;
			margin-left: 30px;
		}
	}
	.whyWe p {
		margin: 0;
	}
	.whySlider {
		.slick-prev {
			left: 0;
		}
		.slick-next {
			right: 0;
		}
	}
	.customers {
		.slick-prev {
			left: 0;
		}
		.slick-next {
			right: 0;
		}
	}

	.footer {
		nav {
			ul {
				flex-direction: column;
				li {
					margin-bottom: 25px;
				}
			}
		}
	}


}
@media screen and (max-width: 768px) {
	.whyWe p {
		margin: 0;
	}

}
@media screen and (max-width: 576px) {
	.offerSlider__title {
		font-size: 30px;
	}
	.newsCard {
		flex-direction: column;
	}
	.slick-arrow {
		display: none !important;
	}
	.whyWe__btn {
		margin: 0 auto !important;
	}
}
@media screen and (max-width: 400px) {
	.mobileSideBar {
		flex-direction: column !important;
	}
	.filters {
		margin-top: 20px;
	}
}




